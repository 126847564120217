import apiAuth from "@/apis/auth/auth";
import orchestrator from "@/apis/auth/starlink";
import starlink from "@/apis/starlink/starlink";
import app from "@/apis/aplication/aplication";
import mail from "@/apis/mail/mail";
import mailnest from "@/apis/mail/mail-nest";
import google from "@/apis/google/google";
import grafana from "@/apis/grafana/grafana";
import router from "@/router";
import { getToken, getTokenRefresh, setToken, setTokenRefresh, removeToken } from "@/utils/auth";
import incident from "@/apis/incident/incident";
import flask from "@/apis/flask/flask"
export default {
  state: {
    user: null,
    token: getToken(),
    tokenRefresh: getTokenRefresh(),
    coordenateData: [],
    permisionData:null,
    principalSelected:null,
    accountSelected:null,
    myColumns: [],
    isLoading: false,
    isLoadingInter: false
  },
  getters: {
    islogin: (state: { user: any }) => {
      if (!state.user) {
        return false;
      }
      return true;
    },
    permissions: (state: { permisionData: any }) => {
      if (!state.permisionData) {
        return [];
      }
      return state.permisionData;

    },
  },
  mutations: {
    SET_USER: (state: { user: any }, values: any) => {
      state.user = values;
    },
    SET_PERMISION: (state: { permisionData: any }, values: any) => {
      state.permisionData = values;
    },
    SET_PRINCIPAL_SELECTED: (state: { principalSelected: any }, values: any) => {
      state.principalSelected = values;
    },
    SET_ACCOUNT_SELECTED: (state: { accountSelected: any }, values: any) => {
      state.accountSelected = values;
    },
    SET_ISLOADING: (state: { isLoading: boolean }, value: boolean) => {
      state.isLoading = value;
    },
    SET_ISLOADING_INTER: (state: { isLoadingInter: boolean }, value: boolean) => {
      state.isLoadingInter = value;
    },
    SET_MYCOLUMS: (state: { myColumns: any }, values: any) => {
      state.myColumns = values;
    },
    SET_COORDENATE: (state: { coordenateData: any }, values: any) => {
      state.coordenateData = values;
    },
    SET_query: (state: { query: any }, values: any) => {
      state.query.text = values;
    },
    SET_column: (state: { dataFilter: any }, values: string) => {
      state.dataFilter.column = values;
    },
    SET_mode: (state: { dataFilter: any }, values: string) => {
      state.dataFilter.mode = values;
    },
  },
  actions: {
    LOGIN: (rootstate: any, user: any) => {
      rootstate.commit("SET_ISLOADING_INTER", true);
      return new Promise((resolve, reject) => {
        apiAuth
          .login(user)
          .then((response) => {
            setToken(response.data.accessToken);
            setTokenRefresh(response.data.refreshToken);
            rootstate
            .dispatch("GET_USER_INFO",{"type": 2, "endpoint": 2})
            .then((user: any) => {
              rootstate.dispatch("showToast", {
                message: "Inicio de sesion correcto",
                type: "success",
              });
              router.push("/home");
            })
            .catch((erroruser: any) =>
             {
              console.log("error", erroruser);
            });
            resolve(response);
          
          })
          .catch((err) => {
            if (err) {
              rootstate.commit("SET_ISLOADING_INTER", false);
              reject(false);
              
            }
          });
      }).catch((erroruser: any) => {
        rootstate.commit("SET_ISLOADING_INTER", false);
        console.log("error", erroruser);
      });
    },
    RECOVER: (rootstate: any, user: any) => {
      rootstate.commit("SET_ISLOADING_INTER", true);
      return new Promise((resolve, reject) => {
        apiAuth
          .recover(user)
          .then((response) => {
            
            rootstate.dispatch("showToast", {
              message: "Reset password",
              type: "success",
            });
            resolve(response);
          
          })
          .catch((err) => {
            if (err) {
              rootstate.commit("SET_ISLOADING_INTER", false);
              reject(false);
              
            }
          });
      }).catch((erroruser: any) => {
        rootstate.commit("SET_ISLOADING_INTER", false);
        console.log("error", erroruser);
      });
    },
    REFRESH_TOKEN: (rootstate: any, token:any) => {
      return new Promise((resolve, reject) => {
        apiAuth
          .refresh_token(token)
          .then((response) => {
            setToken(response.data.accessToken);
            setTokenRefresh(response.data.refreshToken)
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GET_USER_INFO: (rootstate: any, data: any) => { 
      return new Promise((resolve, reject) => {
        rootstate.commit("SET_ISLOADING", true);
        app
          .getUserInfo(data)
          .then((response) => {
            rootstate.commit("SET_USER", response.data.data.user);
            rootstate.commit("SET_PERMISION", response.data.data.web);
            rootstate.commit("SET_ISLOADING", false);
            rootstate.commit("SET_ISLOADING_INTER", false);
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              rootstate.commit("SET_ISLOADING", false)
              rootstate.commit("SET_ISLOADING_INTER", false);
              reject(false);
            }
          });
      });
    },
    LOGOUT: (rootstate: any) => {
      return new Promise((resolve, reject) => {
        
        apiAuth
          .logout()
          .then((response) => {
            rootstate.commit("SET_USER", null);
            removeToken();
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              rootstate.commit("SET_USER", null);
            removeToken();
              reject(false);
            }
          });
      });
    },
    UPDATED: (rootstate: any, user: any) => {
      var newdata = {
        email: user.email,
        fullname: user.fullname,
      };
      return new Promise((resolve, reject) => {
        apiAuth
          .update(user.id, newdata)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    UPDATED_PASSWORD: (rootstate: any, user: any) => {
      var newdata = {
        password: user.password,
      };
      return new Promise((resolve, reject) => {
        apiAuth
          .update(user.id, newdata)
          .then((response) => {
            rootstate.commit("SET_USER", null);
            removeToken();
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    SERVICELINEUSERCONFIG: (rootstate: any, data: any) => {
      rootstate.commit("SET_ISLOADING_INTER", true);
      return new Promise((resolve, reject) => {
        app
        .getTerminalsInfo(data)
        .then((response) => {
          if (data.endpoint === 1) {
            rootstate.commit("SET_arrayData", response.data.data);
          }
          rootstate.commit("SET_ISLOADING_INTER", false);
          resolve(response);
        })
        .catch((err) => {
          if (err) {
            rootstate.commit("SET_ISLOADING_INTER", false);
            reject(false);
          }
        });
      });
    },
    VERIFYADDRESS: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        app
        .getApiCallMap(data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          if (err) {
            reject(false);
          }
        });
      });
    },
    // SERVICEPLAN: (rootstate: any, data: any) => {
    //   return new Promise((resolve, reject) => {
    //     starlink
    //       .serviceplan(data)
    //       .then((response) => {
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         if (err) {
    //           reject(false);
    //         }
    //       });
    //   });
    // },
    // CUSTOMER: (rootstate: any, data: any) => {
    //   return new Promise((resolve, reject) => {
    //     starlink
    //       .customer(data)
    //       .then((response) => {
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         if (err) {
    //           reject(false);
    //         }
    //       });
    //   });
    // },
    // GET_COORDENADE: (rootstate: any, data: any) => {
    //   return new Promise((resolve, reject) => {
    //     google
    //       .getCoordenateByAddress(data)
    //       .then((response) => {
    //         rootstate.commit("SET_COORDENATE", response.data.features);
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         if (err) {
    //           reject(false);
    //         }
    //       });
    //   });
    // },
    // GET_VALID_COORDENADE: (rootstate: any, data: any) => {
    //   return new Promise((resolve, reject) => {
    //     orchestrator
    //       .validAddressStarlink(data)
    //       .then((response) => {
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         if (err) {
    //           reject(err);
    //         }
    //       });
    //   });
    // },
    // GET_VALID_COORDENADE_PUT: (rootstate: any, data: any) => {
    //   return new Promise((resolve, reject) => {
    //     orchestrator
    //       .validAddressStarlinkput(data)
    //       .then((response) => {
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         if (err) {
    //           reject(err);
    //         }
    //       });
    //   });
    // },
    SEND_MAIL: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        mail
          .sendmail(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    SEND_MAIL_NEST: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        mailnest
          .sendmailnest(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    // SERIALNUMBER: (rootstate: any, data: any) => {
    //   return new Promise((resolve, reject) => {
    //     starlink
    //       .serialnumber(data)
    //       .then((response) => {
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         if (err) {
    //           reject(false);
    //         }
    //       });
    //   });
    // },
    HELLOWORD: (rootstate: any) => {
       return new Promise((resolve, reject) => {
         orchestrator
           .helloword()
           .then((response) => {
             console.log(response);
             resolve(response);
           })
           .catch((err) => {
             if (err) {
               reject(false);
             }
           });
       });
     },
    SOPORTE: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        incident
          .soporte(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      }).catch((erroruser: any) => {
        console.log("error", erroruser);
      });
    },
    PROBLEMTYPE: (rootstate:any,data:any) => {
     
      return new Promise((resolve, reject) => {
        incident
          .problemtype(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              
              reject(err);
            }
          });
      });
    },
    CREATEDSUPPORT: (rootstate:any,data:any) => {
      return new Promise((resolve, reject) => {
        incident
          .createdSupport(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(err);
            }
          });
      });
    },

    GETMONITORINGURL: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        grafana
          .getMonitoringUrl(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GETMONITORINGURLEN: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        grafana
          .getMonitoringUrlEN(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GETMONITORINGURLPT: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        grafana
          .getMonitoringUrlPT(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GETMONITORINGURLPRINCIPAL: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        grafana
          .getMonitoringUrlPrincipal(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GETMONITORINGURLPRINCIPALES: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        grafana
          .getMonitoringUrlPrincipalES(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GETMONITORINGURLPRINCIPALPT: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        grafana
          .getMonitoringUrlPrincipalPT(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    // CONFIG_FUNCTION: (rootstate: any, data: any) => {
    //   return new Promise((resolve, reject) => {
    //     app
    //       .functionConfig(data)
    //       .then((response) => {
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         if (err) {
    //           reject(err);
    //         }
    //       });
    //   });
    // },
    DOWNLOADFILE: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        mailnest
          .downloadFile(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    
    UPLOADFILE: (rootstate: any, formData: FormData) => {
      return new Promise((resolve, reject) => {
        mailnest
          .uploadFile(formData)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },

    INSERTFILE:(rootstate: any, data: any) =>{
      return new Promise((resolve, reject) => {
        flask
          .insertFile(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
  },
};
