<template>
  <Popup>
    <div class="header-content">
      <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
        src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
      <h1 style="
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 3rem;
        color: white;
        font-weight: 100;
      ">
        {{ $t('message.planta.f1') }}
      </h1>
    </div>

    <div>
      <form class="popup-form" @submit.prevent="verificarAddress()">
        <div class="group">
          <input required v-model="newService.direction" class="custom-input" type="text"
            :placeholder="$t('message.planta.f2')" style="padding-left: 0.5rem" />
        </div>
        <div class="btn-content">
          <button class="btn-submit-address" type="submit">
            {{
              coordenate.length > 0
                ? $t('message.planta.f3')
                : $t('message.planta.f4')
            }}
          </button>
        </div>
      </form>
    </div>

    <div style="margin: 20px 0px" v-if="validAddress">
      <Map :height="200" :width="320"></Map>
    </div>

    <form v-if="validAddress" class="popup-form" @submit.prevent="newServices()">
      <div class="group">
        <input v-model="coordenate.requestBody.nickname" class="custom-input" type="text" required
          :placeholder="$t('message.planta.f5')" style="padding-left: 0.5rem" />
      </div>

      <div class="group">
        <v-select v-model="coordenate.requestBody.serviceplanId" :options="changeplan" label="name" required
          :placeholder="$t('message.planta.f6')" class="custom-select" />
      </div>
      <div v-if="newData" class="group">
        <input disabled v-model="coordenate.requestBody.regionCode" class="custom-input" type="text"
          :placeholder="$t('message.planta.c11')" style="padding-left: 0.5rem" />
      </div>
      <div v-if="newData" class="group">
        <input disabled v-model="coordenate.requestBody.latitude" class="custom-input" type="text"
          :placeholder="$t('message.planta.f16')" style="padding-left: 0.5rem" />
      </div>
      <div v-if="newData" class="group">
        <input disabled v-model="coordenate.requestBody.longitude" class="custom-input" type="text"
          :placeholder="$t('message.planta.f15')" style="padding-left: 0.5rem" />
      </div>
      <div class="group">
        <v-select required v-model="coordenate.requestBody.deviceId" :options="serial" label="kitSerialNumber"
          :placeholder="$t('message.planta.f7')" class="custom-select" />
      </div>
      <!-- <div class="group">
            <v-select
              v-model="newService.suscriptor"
              :options="suscriptores"
              label="name"
              :placeholder="'Seleccionar Suscriptor'"
              class="custom-select"
            />
          </div> -->

      <div class="btn-content" v-if="!isLoadingNewServices">
        <button :disabled="process" class="btn-submit" type="submit" style="margin-left: 1rem">
          <div style="
              display: flex;
              justify-content: space-evenly;
              width: 90%;
              margin: auto;
              align-items: center;
            ">
            <div>{{ $t('message.planta.f8') }}</div>
            <div>
              <img src="@/assets/Vector-servicio.png" alt="img-vector" />
            </div>
          </div>
        </button>
      </div>

      <div v-else class="spinner-content2">
        <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" animationDuration=".5s"
          aria-label="Custom ProgressSpinner" />
      </div>
    </form>
  </Popup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Popup from "../../components/planta-conte/Popup.vue";
import store from "../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import Map from "../../components/map/index.vue";
import vSelect from "vue-select";

export default defineComponent({
  name: "newService",
  emits: ["close"],
  components: {
    Popup,
    Map,
    vSelect,
  },
  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.user,
      principal: (state: any) => state.AUTH.principalSelected,
      account: (state: any) => state.AUTH.accountSelected,
      token: (state: any) => state.AUTH.token
    }),
    ...mapGetters(["permissions"]),

  },
  data() {
    return {
      isLoadingNewServices: false,
      showPopup: false,
      showPopupDrow: false,
      showPopupDrowSus: false,
      showPopupDrowBaja: false,
      showPopupDrowSoporte: false,
      validAddress: false,
      process: false,
      accounts: [],
      newService: {
        nickname: "",
        planservice: "",
        direction: "",
        kitSerialNumber: "",
        suscriptor: "",
        addressLines: "",
        locality: "",
        administrativeArea: "", // mayuscula
        administrativeAreaCode: "", // mayuscula
        region: "",
        regionCode: "", // mayuscula
        postalCode: "",
        latitude: "",
        longitude: "",
        formattedAddress: "",
        productReferenceId: "",
      },
      newData: {
        nickname: null,
        device: null,
        planservice: null,
      },
      changeplan: [],
      suscriptores: [],
      newdirection: null,
      serial: [],
    };
  },
  mounted() {

    // this.customer();
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    serviceplan() {
      var data = {
        endpoint: 14,
        type: "data",
        variables: [
          {
            name: "$principalId",
            value: this.principal.principalId
          },
          {
            name: "$onlyserviceplans",
            value: "serviceplans"
          }
        ]
      }
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          this.changeplan = response.data.data;
          console.log("services", this.changeplan);
          this.changeplan = response.data.data.map(function (
            cadena: any,
          ) {
            return {
              id: cadena.optionsId, name: cadena.name, productReferenceId: cadena.productReferenceId, serviceplanId: cadena.serviceplanId
            };
          });
          this.serialnumber();


        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    customer() {
      var data = {
        endpointId: 4,
        token: this.token,
        principalId: this.user.principalId,
        platform: "starlink",
        roleId: this.user.roleId,
        userId: this.user.id,
        orgId: this.user.orgId,
      }
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          this.suscriptores = response.data.data;
          this.suscriptores = response.data.data.map(function (
            cadena: any,
          ) {
            return cadena.name;
          });
          this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    serialnumber() {
      var data = {
        endpoint: 15,
        type: "data",
        variables: [
          {
            name: "$principalId",
            value: this.principal.principalId
          },
          {
            name: "$neednull",
            value: "nulo"
          }
        ]
      }
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          this.serial = response.data.data;
          this.serial = response.data.data.map(function (
            cadena: any,
          ) {
            return { id: cadena.id, userTerminalId: cadena.userTerminalId, kitSerialNumber: cadena.kitSerialNumber };
          });
          this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    serviceline(data: any) {
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          store.dispatch("showToast", {
            message: "LISTADO DE PLANTA",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    newServices() {
      this.isLoadingNewServices = true
      this.process = true;

      store.dispatch("VERIFYADDRESS", this.coordenate).then((response2) => {
        console.log(response2);
        if (response2.data.code === 200) {
          this.validAddress = true;
          if (response2.data.data.results[1].code === 200) {
            store.dispatch("showToast", {
              message: "Direccion Válida",
              type: "success",
            });
            var data = {
              endpoint: 22,
              type: "api",
              requestBody: {
                accountNumber: this.account.name,
                platformId: process.env.VUE_APP_PLATFORM,
                principalId: this.principal.principalId,
                deviceId: response2.data.data.requestResults.deviceId.id,
                directionId: response2.data.data.results[1].data.id,
                serviceplanId: response2.data.data.requestResults.serviceplanId.serviceplanId,
                nickname: response2.data.data.requestResults.nickname
              }
            }
            store.dispatch("VERIFYADDRESS", data).then((response3) => {
              if (response3.data.code === 200) {
                store.dispatch("showToast", {
                  message: "Creada Correctamente",
                  type: "success",
                });
                var data2 = {
                  endpoint: 1,
                };
                this.serviceline(data2);
                this.$emit("close");
                this.isLoadingNewServices = false
              } else {
                store.dispatch("showToast", {
                  message: "Error al crear",
                  type: "error",
                });
                this.isLoadingNewServices = false
              }

            }).catch((error) => {
              this.validAddress = false;
              store.dispatch("showToast", {
                message: String(error.response.data.message[0]),
                type: "error",
              });
            });

            console.log(data)
          } else {
            store.dispatch("showToast", {
              message: "Error al Crear Direccion",
              type: "success",
            });
            this.isLoadingNewServices = false
          }
        } else {
          this.validAddress = false;
          store.dispatch("showToast", {
            message: response2.data.message[0],
            type: "error",
          });
        }
    
      }).catch((error) => {
        this.validAddress = false;
        this.isLoadingNewServices = false
        store.dispatch("showToast", {
          message: String(error.response.data.message[0]),
          type: "error",
        });
      });



    },

    verificarAddress() {
      // console.log("coordenate", this.coordenate)
      if (this.permissions.some((evt: any) => evt.id === 7)) {
        var dataValid = {
          endpoint: 18,
          type: "api",
          requestBody: {
            $address: this.newService.direction
          }
        }
        store.dispatch("VERIFYADDRESS", dataValid).then((response2) => {
          // console.log("VERIFYADDRESS", response2);
          if (response2.data.code === 200) {
            //   console.log('status 200');
            // this.validAddress = true;
            if (response2.data.data.results.length > 0) {
              //     console.log('hau resultados');
              var dataValue = response2.data.data.results[0];
              // console.log("dataValue", dataValue)
              var country = {
                long_name: null,
                short_name: null
              };
              var postalCodeRef = {
                long_name: null,
                short_name: null
              };
              var localCodeRef = {
                long_name: null,
                short_name: null
              };
              dataValue.results[0].address_components.forEach((component: any) => {
                component.types.forEach((evt: any) => {
                  if (evt === "country") {
                    country = component;
                  }
                  if (evt === "postal_code") {
                    postalCodeRef = component;
                  }
                  if (evt === "locality") {
                    localCodeRef = component;
                  }
                })
              });
              var dataValid = {
                endpoint: 19,
                type: "api",
                requestBody: {
                  nickname: null,
                  deviceId: null,
                  serviceplanId: null,
                  accountNumber: this.account.name,
                  principalId: this.principal.principalId,
                  platformId: process.env.VUE_APP_PLATFORM,
                  addressLines: response2.data.data.results[0].results[0].formatted_address,
                  locality: localCodeRef ? localCodeRef.long_name : "",
                  administrativeArea: country ? country.short_name : "", // mayuscula
                  administrativeAreaCode: country ? country.short_name : "", // mayuscula
                  region: country ? country.long_name : "",
                  regionCode: country ? country.short_name : "", // mayuscula
                  latitude: response2.data.data.results[0].results[0].geometry.location.lat,
                  longitude: response2.data.data.results[0].results[0].geometry.location.lng,
                  formattedAddress: response2.data.data.results[0].results[0].formatted_address,
                  postalCode: postalCodeRef ? postalCodeRef.long_name : "",
                }
              }

              // console.log("dataValid", dataValid)
              store.commit("SET_COORDENATE", dataValid);
              // console.log('ok');
              this.serviceplan();
              this.validAddress = true;

            } else {
              store.dispatch("showToast", {
                message: "NO ENCONTRO DIRECCIÓN",
                type: "error",
              });
            }
            store.dispatch("showToast", {
              message: "VERIFICADO",
              type: "success",
            });
          } else {
            this.validAddress = false;
            store.dispatch("showToast", {
              message: response2.data.message[0],
              type: "error",
            });
          }
          // console.log(response2);
        }).catch((error) => {
          this.validAddress = false;
          store.dispatch("showToast", {
            message: String(error.response.data.message[0]),
            type: "error",
          });
        });
      } else {
        this.process = false;
        store.dispatch("showToast", {
          message: "NO TIENES PERMISOS PARA ESTA ACCION",
          type: "error",
        });
      }



    },

    enviarCorreo(value: any) {
      var data = {
        body: `Estimados Buen día.
        Se realizó el alta del servicio ${value.nickname} asociado al KIT ${value.device.kitSerialNumber}, 
        se requiere actualizar la politica de IP a "Public IP" en plataforma Starlink.`,
        asunto: `ALTA DE SERVICIO ${value.nickname}`,
        correo_destino: "nocmnla.fija.pe@telefonica.com",
        cc: ["alejandro.chacon@telefonica.com", "orderingmn.pe@telefonica.com", "elvis.shahuano@wydsoluciones.com", "andres.tello@wydsoluciones.com"]
      };
      store
        .dispatch("SEND_MAIL", data)
        .then((response) => {
          store.dispatch("showToast", {
            message: "CORREO ENVIADO EXITOSAMENTE",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });

    },
  },
});
</script>

<style lang="scss">
.dropdown-content {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: 0px;
}

.dropdown-content:last-of-type {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: -225px;
  margin-left: 45px;
}

.dropdown-content:hover {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
}

.dropdown-content a:hover {
  background-color: #0079ff;
}

.btn-submit-address {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.btn-submit-mapa {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
  background-color: white;
}

.custom-input {
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  width: 95%;
  cursor: pointer;
}

.header-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.spinner-content2 {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-width: 1300px;
  // min-height: 600px;
}
</style>
